<template>
  <div>
    <!-- แถบสถานะ Order -->
    <!-- <h4>ทำ price in booking and price in order miss match</h4> -->
    <b-alert show variant="danger" class="mt-1" v-if="order_info.is_delete">
      <div class="alert-body">
        <span style="font-size: 16px">Order นี้ถูกลบไปแล้ว !!!</span>
      </div>
    </b-alert>
    <order-status :info="order_info" v-if="selected_tab == 1"></order-status>
    <b-tabs v-model="activeTabIndex">
      <!-- ข้อมูล Order -->
      <b-tab title="ข้อมูล Order">
        <b-card>
          <!-- class="d-flex justify-content-between" -->
          <div>
            <b-button
              size="sm"
              class="ml-1"
              variant="relief-success"
              v-b-modal.extend-rental-modal
            >
              เช่าต่อ
            </b-button>
            <b-button
              size="sm"
              class="ml-1"
              variant="relief-warning"
              v-b-modal.modal-change-date
              >เปลี่ยนวันรับ-คืน</b-button
            >
            <b-button
              size="sm"
              class="ml-1"
              variant="relief-success"
              @click="print"
              >พิมสัญญาเช่า</b-button
            >
            <b-button
              size="sm"
              class="ml-1"
              variant="relief-warning"
              v-b-modal.modal-change-status
            >
              เปลี่ยนสถานะ Order
            </b-button>
            <b-button
              size="sm"
              class="ml-1"
              variant="relief-success"
              v-b-modal.modal-change-pickup-status
              >รับ-คืน</b-button
            >
          </div>
        </b-card>
        <b-row>
          <!-- ข้อมูลผู้เช่า -->
          <b-col md="12">
            <div class="d-flex justify-content-between">
              <div></div>
              <div>
                <b-button
                  size="sm"
                  v-if="order_info.member_id"
                  variant="relief-success"
                  class="btn-icon mb-1 ml-1"
                  @click="
                    $router.push({
                      name: 'member-view',
                      params: { id: order_info.member_id },
                    })
                  "
                >
                  <feather-icon icon="SearchIcon" />
                </b-button>
                <b-button
                  size="sm"
                  variant="relief-warning"
                  class="btn mb-1 ml-1 mr-1"
                  v-b-modal.modal-change-member
                >
                  เปลี่ยนผู้เช่า
                </b-button>
                <confirm-delete-button
                  class="mbl-0 mb-1"
                  title="ลบผู้เช่า"
                  @delete="remove_member"
                  size="sm"
                  :data="order_info.order_id"
                />
              </div>
            </div>
            <member-info
              :member_id="order_info.member_id"
              :button="false"
              v-if="order_info.member_id"
              @member_info="member_info"
            ></member-info>
            <div v-else>
              <b-card>
                <h4>ยังไม่มีผู้เช่า</h4>
              </b-card>
            </div>
          </b-col>
        </b-row>

        <b-row>
          <!-- ข้อมูลการเช่า -->
          <b-col md="6" sm="12">
            <b-card>
              <div class="d-flex justify-content-between">
                <h4>ข้อมูลการเช่า</h4>
                <div>
                  <b-button
                    size="sm"
                    class="ml-1 mb-1"
                    variant="relief-warning"
                    v-b-modal.edit-order-modal
                    >แก้ไข Order</b-button
                  >
                  <confirm-delete-button
                    class="mb-1 ml-1"
                    title="ลบ ORDER"
                    @delete="deleteOrder"
                    :data="order_info.order_id"
                  />
                </div>
              </div>
              <hr class="mb-0" />
              <order-info
                :info="order_info"
                :booking="booking"
                v-if="order_info._id != null"
              />
            </b-card>

            <note-log-list
              :update_id="id"
              location="order"
              title="Order Note"
            ></note-log-list>
          </b-col>
          <!-- รายการอุปกรณ์ที่จอง -->
          <b-col md="6" lg="6">
            <!-- รายการอุปกรณ์ที่เช่า -->
            <b-card>
              <booking-list
                :info="order_info"
                @booking="updateBooking"
                v-if="order_info._id"
              />
            </b-card>
            <!-- อุปกรณ์จัดชุด  -->
            <b-card>
              <h4>อุปกรณ์จัดชุด</h4>
              <hr />
              <kit-booking-list></kit-booking-list>
            </b-card>
            <!-- รวม Order -->
            <combine-order> </combine-order>
            <other-order :member_id="order_info.member_id"></other-order>
          </b-col>
        </b-row>
      </b-tab>
      <b-tab title="ชำระเงิน">
        <b-col md="12" lg="12">
          <!-- เงินเข้าออก -->
          <transaction-log />
        </b-col>
        <!-- การชำระเงิน -->
        <b-col md="12" lg="12">
          <payment-info :info="order_info" />
        </b-col>

        <b-col md="12" lg="12">
          <!-- ประวัติการชำระเงิน -->
          <payment-log />
        </b-col>

        <b-col>
          <h4>ใบกำกับภาษี</h4>
          <taxinvoice-list :info="order_info"></taxinvoice-list>
        </b-col>

        <b-col md="12" lg="6" v-if="have_transfer_slip">
          <!-- แจ้งโอนเงิน -->
          <h4>สลิปโอนเงิน</h4>
          <transfer-slip :info="order_info.transfer_slip" />
        </b-col>
      </b-tab>
      <b-tab title="กันวงเงิน">
        <!-- กันวงเงิน -->
        <b-col md="12">
          <card-deposit :info="order_info"></card-deposit>
        </b-col>
      </b-tab>
      <b-tab title="เตรียมอุปกรณ์">
        <!-- รูป Stock -->
        <b-col md="12">
          <stock-image :info="order_info"></stock-image>
        </b-col>
      </b-tab>

      <b-tab title="รับอุปกรณ์"> </b-tab>
      <b-tab title="คืนอุปกรณ์">
        <b-card-text>
          Biscuit macaroon sugar plum sesame snaps oat cake halvah fruitcake
          pudding cotton candy. Cheesecake tart wafer soufflé. Chocolate
          marzipan donut pie soufflé dragée cheesecake. Gummi bears dessert
          croissant chocolate jujubes fruitcake. Pie cupcake halvah.
        </b-card-text>
        <b-card-text
          >Tiramisu carrot cake marzipan sugar plum powder marzipan sugar plum
          bonbon powder. Macaroon jujubes ice cream sugar plum lollipop wafer
          caramels. Cheesecake chocolate tart cake gingerbread fruitcake cake
          candy jelly-o. Candy cookie lollipop. Wafer lemon drops chocolate cake
          gummi bears.</b-card-text
        >
      </b-tab>

      <b-tab title="Logs">
        <order-log></order-log>
      </b-tab>
    </b-tabs>
    <b-row>
      <!-- ปุ่ม -->
      <b-col md="12">
        <div></div>
      </b-col>
    </b-row>
    <toast />
  </div>
</template>

<script>
import {
  BSpinner,
  BCard,
  BCardText,
  BRow,
  BCol,
  BTab,
  BTabs,
  BButton,
  BAlert,
} from "bootstrap-vue";

import OrderLog from "./component/OrderLog.vue";
import ConfirmDeleteButton from "../components/ConfirmDeleteButton.vue";
import TransferSlip from "./component/TransferSlipList.vue";
import TaxinvoiceList from "./component/TaxinvoiceList.vue";
import OrderPickupStatusSwitch from "./component/OrderPickupStatusSwitch.vue"; /// สถานะ Order
import OrderStatusSwitch from "./component/OrderStatusSwitch.vue"; /// สถานะ Order
import OrderStatus from "./component/OrderStatus.vue"; /// สถานะ Order
import OrderInfo from "./component/OrderInfo.vue"; /// ข้อมูลการเช่า
import PaymentInfo from "./component/PaymentInfo.vue"; /// ข้อมูลการชำระเงิน
import PaymentLog from "./component/PaymentLog.vue"; /// ประวัติ"การชำระเงิน
import BookingList from "./component/BookingList.vue"; /// รายการอุปกรณ์ที่เช่า
import KitBookingList from "./component/KitBookingList.vue"; /// รายการอุปกรณ์ที่เช่าจัดชุด
import UserInfo from "./component/UserInfo.vue"; /// ข้อมูลผู้เช่า
import StockImage from "./component/StockImage.vue"; /// รูปสภาพอุปกรณ์
import CardDeposit from "./component/CardDeposit.vue";
import TransactionLog from "./component/TransactionLog.vue";
import Ripple from "vue-ripple-directive";
import _ from "lodash";
import toast from "../components/Toast.vue";
import MemberInfo from "../member/component/MemberInfo.vue";
import CombineOrder from "./component/CombineOrder.vue";
import OtherOrder from "./component/OtherOrder.vue";
import NoteLogList from "../components/NoteLogList.vue";

export default {
  components: {
    BAlert,
    BTab,
    BTabs,
    BSpinner,
    BCard,
    BCardText,
    BRow,
    BCol,
    BTab,
    BTabs,
    OrderInfo,
    OrderLog,
    BButton,
    PaymentInfo,
    PaymentLog,
    BookingList,
    KitBookingList,
    UserInfo,
    OrderStatus,
    OrderStatusSwitch,
    OrderPickupStatusSwitch,
    ConfirmDeleteButton,
    StockImage,
    CardDeposit,
    TransferSlip,
    TransactionLog,
    toast,
    MemberInfo,
    CombineOrder,
    TaxinvoiceList,
    OtherOrder,
    NoteLogList,
  },
  directives: {
    Ripple,
  },
  computed: {
    id() {
      return this.$route.params.id || this.$route.query.id; // Adapt based on your route structure
    },
  },
  data() {
    return {
      order_info: {
        _id: null,
      },
      loading: {
        price_reload: false,
      },
      button_variant: [],
      selected_tab: 1,
      booking: [],
      have_transfer_slip: false,
      activeTabIndex: 0,
      dynamicTitle: "Default Title",
    };
  },
  async created() {
    await this.get_order();
    this.tab_select(1);
  },
  mounted() {
    /// เปลี่ยนมาใช้อันนี้ให้
    this.$root.$on("order-reload", async () => {
      await this.get_order();
      // this.orderPriceReload();
    });
  },
  methods: {
    member_info(m) {
      document.title = `${this.order_info.order_id} - ${m.first_name} ${m.last_name}`;
    },
    print() {
      this.$router.push({ path: `/order/print/${this.$route.params.id}` });
    },
    async orderPriceReload() {
      // if (this.order_info.price_mode === "auto") {
      //   this.loading.price_reload = true;
      //   await this.$http({
      //     method: "POST",
      //     url: `/order/price-reload`,
      //     data: {
      //       order_id: this.$route.params.id,
      //     },
      //   }).then((x) => {
      //     // this.$root.$emit('order-reload')
      //     this.loading.price_reload = false;
      //   });
      // } else {
      //   this.loading.price_reload = false;
      //   console.log("not reload price");
      // }
    },
    tab_select(tab_id) {
      this.button_variant = [...Array(5)].fill("xxx"); /// color highlight
      this.button_variant[tab_id] = "primary";
      this.selected_tab = tab_id;
    },
    async get_order() {
      await this.$http({
        method: "GET",
        url: `/order/${this.$route.params.id}`,
        data: {
          side: "all",
        },
      })
        .then((x) => {
          this.order_info = x.data.data;
          if (_.size(this.order_info.transfer_slip) > 0) {
            this.have_transfer_slip = true;
          }
          // this.order_info.order_id_text = x.data.order_id
          // this.order_info.order_id =  x.data._id
          document.title = `${this.order_info.order_id}`;
          this.$root.$emit("member-reload", x.data.data.member_id);
        })
        .catch((e) => {
          // this.$router.push({name:'order-list'})
          console.log("error", e);
        });
    },

    async updateBooking(x) {
      this.booking = await x;
    },

    async deleteOrder(x) {
      if (x.confirm === true) {
        await this.$http({
          method: "DELETE",
          url: `/order/${this.$route.params.id}`,
        }).then((x) => {
          this.$router.push({ name: "order-list" });
        });
      }
    },

    async remove_member(x) {
      if (x.confirm === true) {
        const form = {};
        form.order_id = this.$route.params.id;
        console.log("this.form", form);
        this.$http({
          method: "DELETE",
          url: "/order/remove/member",
          data: form,
        })
          .then(async (x) => {
            if (x.data.success) {
              this.$root.$emit("order-reload");
            } else {
            }
          })
          .catch((e) => {
            console.log("change member error", e.response.data.error);
          });
      }
    },
  },
};
</script>

<style></style>
