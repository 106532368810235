<template>
  <div>
    <div class="row">
      <div class="col">
        <b-table
          striped
          hover
          :items="logs_data"
          :fields="fields"
          :responsive="true"
        >
          <template #cell(date)="data">
            <span class="text-nowrap">{{ data.item.createdAt | date }}</span>
          </template>
          <template #cell(type)="data">
            <span class="text-nowrap">{{ data.item.type }}</span>
          </template>
          <template #cell(from)="data">
            <span
              v-html="formatJson(data.item.from)"
              class="text-nowrap"
            ></span>
          </template>
          <template #cell(to)="data">
            <span v-html="formatJson(data.item.to)" class="text-nowrap"></span>
          </template>
          <template #cell(action_by)="data">
            <span class="text-nowrap">{{ data.item.action_by }}</span>
          </template>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
import { BCard, BTable } from "bootstrap-vue";
import moment from "moment";
export default {
  components: {
    BCard,
    BTable,
  },
  created() {
    this.load_data();
  },
  data() {
    return {
      logs_data: [],
      fields: [
        {
          key: "date",
          label: "Date",
        },
        {
          key: "type",
          label: "Log type",
        },
        {
          key: "from",
          label: "From",
        },
        {
          key: "to",
          label: "To",
        },
        {
          key: "action_by",
          label: "By",
        },
      ],
    };
  },
  methods: {
    formatJson(jsonString) {
      try {
        const jsonObject = JSON.parse(jsonString);
        let formattedString = "";
        for (const [key, value] of Object.entries(jsonObject)) {
          formattedString += `${key}: ${value}<br>`; // Add <br> for line breaks
        }
        return formattedString;
      } catch (error) {
        return "Invalid JSON";
      }
    },
    async load_data() {
      await this.$http({
        method: "POST",
        url: `/order/log/list`,
        update_id: { order_id: this.$route.params.id },
      })
        .then((x) => {
          this.logs_data = x.data.data;
        })
        .catch((e) => {
          console.log("error", e);
        });
    },
  },
  filters: {
    date: function (date) {
      return moment(date).format("DD-MM-YYYY HH:mm");
    },
    time: function (date) {
      return moment(date).format("HH:mm");
    },
  },
};
</script>
